<template>
  <v-dialog
    :width="isMobile ? '100%' : '732'"
    :attach="attach"
    v-model="dialog"
    @keydown.esc="close()"
    :persistent="hideCancelBtn"
  >
    <div class="dialog-wrapper py-4">
      <DepositFormVoPay
        v-if="dialog"
        ref="DepositFormVoPayRef"
        @addBank="onAddBank"
      />

      <div class="buttons-wrapper">
        <div class="d-flex">
          <AppButton
            v-if="!hideCancelBtn"
            buttonType="outlined"
            color="#2969FF"
            :width="isMobile ? '86%' : '230px'"
            height="40px"
            buttonText="Cancel"
            @click="close"
          />
          <AppButton
            :width="isMobile ? '86%' : '230px'"
            color="#2969FF"
            height="40px"
            rounded
            shaped
            buttonText="Add"
            :class="isMobile ? 'ml-2' : 'ml-8'"
            :disabled="loading || !newBank"
            @click="onDepositFormSubmit"
          >
          </AppButton>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import DepositFormVoPay from "@/components/DepositForm/DepositFormVoPay.vue";
import AppButton from "@/components/ui/AppButton.vue";
import { mapActions } from "vuex";

export default {
  name: "AddNewBankDialog",
  components: { DepositFormVoPay, AppButton },
  props: {
    title: {
      type: String,
      default: "Add New Bank",
    },
    checkSendEvent: {
      type: Boolean,
      default: true,
    },
    attach: {
      type: [String, Boolean],
      default: false,
    },
    hideCancelBtn: {
      type: Boolean,
      default: false,
    },
    idValue: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      isFormValid: false,
      resolve: null,

      newBank: null,
      loading: false,
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.newBank = null;
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      addBankingInfo: "addBankingInfo",
    }),
    async onAddBank(newBank) {
      this.newBank = newBank;

      this.$emit("addBank", newBank);
    },
    open() {
      this.dialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close() {
      this.dialog = false;
      this.resolve(false);
    },
    async onDepositFormSubmit() {
      this.loading = true;

      let params = {
        token: this.newBank.Token,
        connectionType: this.newBank.ConnectionType,
      };
      if (this.idValue) {
        params = {
          ...params,
          ...this.idValue,
        };
      }

      const newBankInfo = await this.addBankingInfo(params);

      this.newBank = { ...this.newBank, ...newBankInfo };
      this.$emit("addBank", this.newBank);
      this.loading = false;
      this.dialog = false;
      this.resolve(this.newBank);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-dialog {
  background: #ffffff;
  &::-webkit-scrollbar {
    width: 11px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background: #dedede;
    border: 2px solid #dedede;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #a7a7a7;
  }
}
@-moz-document url-prefix() {
  ::v-deep .v-dialog {
    scrollbar-color: #a7a7a7 #dedede;
    scrollbar-width: thin;
  }
}
.dialog-wrapper {
  background: white;
  width: 100%;
  height: 100%;
  min-height: 450px;
  position: relative;
}
.dialog-title {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(9, 49, 107, 0.4);
  padding-bottom: 20px;
  padding-top: 20px;
  color: #0f2d59;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
  letter-spacing: 0.444px;
}
.dialog-info {
  border-radius: 1px;
  background: #f2f6ff;
  box-shadow: 2px 2px 15px 0px rgba(173, 173, 173, 0.25);
  height: 52px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  display: flex;
}
.close-icon {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
::v-deep {
  input {
    position: relative;
    top: 6px !important;
  }
  .v-btn {
    min-height: unset !important;
  }
  .field-wrapper {
    width: 100% !important;
  }
  .c-label {
    font-size: 14px !important;
  }
}
.buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep {
    .v-dialog {
      max-height: 100%;
      height: 100%;
      margin: 16px 0 0 0 !important;
    }
  }
  .dialog-wrapper {
    padding: 0 16px;
    height: auto;
  }
  .buttons-wrapper {
    justify-content: unset;
  }
  .main-info-wrapper {
    box-shadow: 2.6666667461395264px 2.6666667461395264px 20px 0
      rgba(173, 173, 173, 0.25);
    padding: 16px;
    ::v-deep {
      .row {
        margin: 0 !important;
      }
    }
  }
  .dialog-title {
    border-bottom: unset;
  }
  .dialog-info {
    height: 32px;
    font-size: 12px;
  }
  .buttons-wrapper {
    margin-top: 109px;
  }
  .dialog-title {
    font-size: 16px;
    padding-bottom: 12px;
    padding-top: 16px;
  }
  .close-icon {
    top: 16px;
  }
}
</style>
