export default {
    Auth: {
        identityPoolId: 'us-east-1:27780244-ae8c-4d9e-81d1-c1176c787eae',
        region: 'us-east-1',
        userPoolId: 'us-east-1_aMupBRhSn',
        userPoolWebClientId: '46oksjck109doktu1e9mgng0uc',
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
        aws_appsync_graphqlEndpoint:
            'https://a7imdamqufa7pmvyow5yb7ayce.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
    },
    Storage: {
        AWSS3: {
            bucket: 'rhenium-stage-storage-graphql',
            region: 'us-east-1'
        }
    }
}
